<template>
  <simple-card :need-footer="false">
    <template v-slot:title-left>Моя квартира</template>
    <template v-slot:content>
      <div v-for="item in myFlatInfo" :key="item.title">
        <div class="text-subtitle-2 font-weight-bold primary--text">
          {{ item.title }}
        </div>
        <div :class="addressClass(item.outer)">
          {{ item.value }}
        </div>
        <small v-if="item.numbers" class="info-strong-text">
          Номер Вашего Лицевого счета изменился с {{ item.numbers }} на
          {{ item.value }} в связи с тем, что ваша Управляющая компания изменила
          Расчетный центр
        </small>
      </div>
    </template>
  </simple-card>
</template>

<script>
import SimpleCard from "./SimpleCard";
import { mapState } from "vuex";
import UserService from "../../services/UserService";

export default {
  name: "MyFlatCard",
  components: { SimpleCard },
  computed: {
    ...mapState("user", ["activeBinding"]),
    myFlatInfo() {
      return UserService.getFlatCardInfo(this.activeBinding);
    },
  },
  methods: {
    addressClass(outer) {
      let res = "text-caption";
      if (outer) {
        res += " info-strong-text";
      }
      return res;
    },
  },
};
</script>

<style scoped>
.info-strong-text {
  font-style: italic;
  color: #ff3333;
}
</style>
