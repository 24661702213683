<template>
  <v-col cols="cols" sm="sm" md="md">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <slot></slot>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "VerticalFormLayout",
  props: {
    title: {
      type: String,
      default: "",
    },
    cols: {
      type: Number,
      default: 12,
    },
    sm: {
      type: Number,
      default: 10,
    },
    md: {
      type: Number,
      default: 8,
    },
  },
};
</script>

<style scoped></style>
