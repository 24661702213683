<template>
  <div id="navigation-card-item">
    <v-list-item
      link
      :key="item.title"
      :to="{ name: item.routeName }"
      @mouseenter="handleItemHoverOn"
      @mouseleave="handleItemHoverOut"
    >
      <v-list-item-avatar>
        <v-icon size="small">{{ itemIcon }}</v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title v-html="item.title"></v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider :inset="false"></v-divider>
  </div>
</template>

<script>
export default {
  name: "NavigationCardItem",
  props: {
    item: Object,
  },
  data() {
    return {
      itemIcon:
        this.item.routeName === this.$route.name
          ? "mdi-circle"
          : "mdi-circle-outline",
    };
  },
  methods: {
    handleItemHoverOn() {
      this.itemIcon = "mdi-circle";
    },
    handleItemHoverOut() {
      if (this.item.routeName !== this.$route.name)
        this.itemIcon = "mdi-circle-outline";
    },
  },
};
</script>

<style lang="scss">
#navigation-card-item {
  .v-list-item__title {
    font-weight: 500;
  }

  .v-list-item--active {
    background-color: #c5dcf4;
  }
}
</style>
