import FormRules from "./FormRules";
import AppealsService from "../services/AppealsService";
import store from "../store";

export default class AppealForm extends FormRules {
  valid = true;
  name = "AppealForm";

  payload = {
    typeId: "",
    fullName: store.getters["user/activeBindingOwner"],
    phone: "",
    email: store.state.user.login,
    text: "",
    // file: null
  };

  fields = {
    typeId: {
      label: "Тема обращения",
      type: "select",
      items: AppealsService.appealTypes,
      itemChangeHandler: (item) => (this.payload.typeId = item),
      rules: [this.required()],
      required: true,
      fullWidth: true,
      // attrs: {
      //   label: 'Тема обращения',
      //   type: 'select',
      //   items: AppealsService.appealTypes,
      //   fullWidth: true,
      // }
    },
    fullName: {
      label: "ФИО",
      type: "text",
      rules: [this.required()],
      required: true,
    },
    phone: {
      label: "Телефон",
      type: "text",
      rules: [this.required(), this.validPhone()],
      required: true,
    },
    email: {
      label: "Электронная почта",
      type: "text",
      rules: [
        this.required(),
        this.validEmail("E-mail должен быть корректным"),
      ],
      required: true,
    },
    text: {
      label: "Текст обращения",
      type: "textarea",
      rules: [this.required()],
      required: true,
    },
    // file: {
    //   label: 'Прикрепить файл',
    //   type: 'file',
    //   rules: []
    // }
  };

  setAppealTypes(types) {
    this.fields.typeId.items = types;
  }

  buttons = [
    {
      label: "Отправить",
      action: async () => {
        // if (this.payload.typeId === "") {
        //   return "Укажите тему"
        // }
        await AppealsService.sendAppeal(this.payload);
        // this.payload.text = ''
      },
      type: "submit",
      useLoading: true,
      resetForm: true,
    },
  ];
}
