<template>
  <div>
    <simple-card id="NavigationCard" :need-footer="false" :need-header="false">
      <!--      <template v-slot:title-left>Мой аккаунт</template>-->
      <template v-slot:content>
        <v-list>
          <v-list-item-group
            v-model="currentItem"
            dense
            color="primary"
            active-class="active-item"
          >
            <navigation-card-item
              v-for="(item, index) in items"
              :item="item"
              :key="index"
            ></navigation-card-item>
          </v-list-item-group>
        </v-list>
      </template>
    </simple-card>
    <confirm-dialog
      :is-open="isLogoutDialogOpen"
      title="Выход"
      question="Выйти из личного кабинета?"
      @close-dialog="isLogoutDialogOpen = false"
      @confirm="confirm"
      @cancel="isLogoutDialogOpen = false"
    ></confirm-dialog>
  </div>
</template>

<script>
import SimpleCard from "../SimpleCard";
import AppService from "../../../services/AppService";
import AuthService from "../../../services/AuthService";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import NavigationCardItem from "./components/NavigationCardItem";

export default {
  name: "NavigationCard",
  components: { ConfirmDialog, SimpleCard, NavigationCardItem },
  data() {
    return {
      items: AppService.navigationCardItems,
      currentItem: 0,
      isLogoutDialogOpen: false,
      itemIcon: "mdi-circle-outline",
    };
  },
  methods: {
    async confirm() {
      await AuthService.logoutUser();
      window.open("https://xn--d1abeikdleg.xn--p1ai/", "_self").focus();
      this.isLogoutDialogOpen = false;
    },
    handleItemHover(isHover) {
      this.itemIcon = isHover ? "mdi-circle" : "mdi-circle-outline";
    },
  },
};
</script>

<style lang="scss">
#NavigationCard {
  .v-card__text {
    padding: 0;

    .v-list {
      padding: 0;
      border-radius: 6px;

      .v-list-item {
        padding: 0;

        &:first-child::before {
          border-radius: 6px 6px 0 0;
        }

        &:last-child::before {
          border-radius: 0 0 6px 6px;
        }

        &:hover {
          background-color: #dfdfe0;
        }

        .v-list-item__avatar {
          margin: 0;
        }

        .v-list-item__content {
          padding-top: 6px;
          padding-bottom: 6px;
        }
      }
    }
  }
}
</style>
