<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" lg="9">
        <v-row class="text-center" :class="rowClass">
          <slot></slot>
        </v-row>
      </v-col>
      <v-col
        v-if="!!hasBindings && $vuetify.breakpoint.mdAndUp"
        cols="4"
        lg="3"
      >
        <div class="d-flex flex-column pb-10">
          <my-flat-card class="mb-6"></my-flat-card>
          <navigation-card></navigation-card>
          <div class="d-flex justify-center social-container mt-2 mt-md-4">
            <a target="_blank" href="https://vk.com/krkc_mo"
              ><svg
                width="25"
                height="21"
                class="svg-inline--fa fa-vk fa-w-18"
                aria-hidden="true"
                data-fa-processed=""
                data-prefix="fab"
                data-icon="vk"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path
                  fill="currentColor"
                  d="M545 117.7c3.7-12.5 0-21.7-17.8-21.7h-58.9c-15 0-21.9 7.9-25.6 16.7 0 0-30 73.1-72.4 120.5-13.7 13.7-20 18.1-27.5 18.1-3.7 0-9.4-4.4-9.4-16.9V117.7c0-15-4.2-21.7-16.6-21.7h-92.6c-9.4 0-15 7-15 13.5 0 14.2 21.2 17.5 23.4 57.5v86.8c0 19-3.4 22.5-10.9 22.5-20 0-68.6-73.4-97.4-157.4-5.8-16.3-11.5-22.9-26.6-22.9H38.8c-16.8 0-20.2 7.9-20.2 16.7 0 15.6 20 93.1 93.1 195.5C160.4 378.1 229 416 291.4 416c37.5 0 42.1-8.4 42.1-22.9 0-66.8-3.4-73.1 15.4-73.1 8.7 0 23.7 4.4 58.7 38.1 40 40 46.6 57.9 69 57.9h58.9c16.8 0 25.3-8.4 20.4-25-11.2-34.9-86.9-106.7-90.3-111.5-8.7-11.2-6.2-16.2 0-26.2.1-.1 72-101.3 79.4-135.6z"
                ></path></svg
            ></a>
            <a target="_blank" href="https://ok.ru/group/54639710109820"
              ><svg
                width="12"
                height="21"
                class="svg-inline--fa fa-odnoklassniki fa-w-10"
                aria-hidden="true"
                data-fa-processed=""
                data-prefix="fab"
                data-icon="odnoklassniki"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  fill="currentColor"
                  d="M275.1 334c-27.4 17.4-65.1 24.3-90 26.9l20.9 20.6 76.3 76.3c27.9 28.6-17.5 73.3-45.7 45.7-19.1-19.4-47.1-47.4-76.3-76.6L84 503.4c-28.2 27.5-73.6-17.6-45.4-45.7 19.4-19.4 47.1-47.4 76.3-76.3l20.6-20.6c-24.6-2.6-62.9-9.1-90.6-26.9-32.6-21-46.9-33.3-34.3-59 7.4-14.6 27.7-26.9 54.6-5.7 0 0 36.3 28.9 94.9 28.9s94.9-28.9 94.9-28.9c26.9-21.1 47.1-8.9 54.6 5.7 12.4 25.7-1.9 38-34.5 59.1zM30.3 129.7C30.3 58 88.6 0 160 0s129.7 58 129.7 129.7c0 71.4-58.3 129.4-129.7 129.4s-129.7-58-129.7-129.4zm66 0c0 35.1 28.6 63.7 63.7 63.7s63.7-28.6 63.7-63.7c0-35.4-28.6-64-63.7-64s-63.7 28.6-63.7 64z"
                ></path></svg
            ></a>
            <a target="_blank" href="https://t.me/NedolzhnikBot"
              ><svg
                width="21"
                height="21"
                class="svg-inline--fa fa-telegram fa-w-16"
                aria-hidden="true"
                data-fa-processed=""
                data-prefix="fab"
                data-icon="telegram"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 496 512"
              >
                <path
                  fill="currentColor"
                  d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm121.8 169.9l-40.7 191.8c-3 13.6-11.1 16.9-22.4 10.5l-62-45.7-29.9 28.8c-3.3 3.3-6.1 6.1-12.5 6.1l4.4-63.1 114.9-103.8c5-4.4-1.1-6.9-7.7-2.5l-142 89.4-61.2-19.1c-13.3-4.2-13.6-13.3 2.8-19.7l239.1-92.2c11.1-4 20.8 2.7 17.2 19.5z"
                ></path></svg
            ></a>
          </div>
          <download />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MyFlatCard from "../cards/MyFlatCard";
import NavigationCard from "../cards/NavigationCard/NavigationCard";
import Download from "@/views/contacts/Download";

export default {
  name: "PageLayout",
  components: {
    Download,
    NavigationCard,
    MyFlatCard,
  },
  props: {
    useMatchHeight: {
      type: Boolean,
      default: true,
    },
    justify: {
      type: String,
      default: "center",
    },
    hasBindings: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    rowClass() {
      return {
        "match-height": this.useMatchHeight,
        [`justify-${this.justify}`]: true,
      };
    },
  },
};
</script>

<style scoped>
.social-container {
  gap: 10px;
}
</style>
