<template>
  <page-layout :use-match-height="true" justify="center">
    <vertical-form-layout title="Обращения" cols="12" sm="12" md="12">
      <simple-form
        :form-instance="form"
        buttons-alignment="start"
      ></simple-form>
    </vertical-form-layout>
  </page-layout>
</template>

<script>
import PageLayout from "../../components/Layouts/PageLayout";
import VerticalFormLayout from "../../components/Layouts/forms/VerticalFormLayout";
import SimpleForm from "../../components/forms/SimpleForm";
import AppealForm from "../../forms/AppealForm";
import AppealsService from "../../services/AppealsService";

export default {
  name: "Appeals",
  components: { PageLayout, VerticalFormLayout, SimpleForm },
  data() {
    return {
      form: new AppealForm(),
    };
  },
  async mounted() {
    const appealTypes = await AppealsService.getAppealsTypes();
    this.form.setAppealTypes(appealTypes);
  },
};
</script>

<style scoped></style>
