import store from "../store";
import ApiService from "./ApiService";
import AppService from "./AppService";

export default {
  appealTypes: [
    {
      id: 4,
      name: "Прочее",
    },
  ],

  async getAppealsTypes() {
    try {
      const types = await ApiService.getAppealsTypes();
      AppService.unsetLoading("get_appealTypes");
      return types;
    } catch (e) {
      store.commit("app/setAlert", {
        shown: true,
        text: "Ошибка получения тем для обращения",
        color: "error",
      });
      return this.appealTypes;
    }
  },

  async sendAppeal(payload) {
    try {
      const accountId = store.getters["user/activeBindingId"];
      const response = await ApiService.sendAppeal(accountId, payload);

      AppService.unsetLoading("post_appeals");

      if (response.result) {
        // store.commit('app/setAlert', {shown: true, text: 'Обращение успешно отправлено', color: 'success'})
        store.commit("app/setModal", {
          shown: true,
          title: "Успех",
          text: "Обращение успешно отправлено",
          color: "success",
        });
      } else {
        throw new Error("Ошибка отправки обращения");
      }
    } catch (e) {
      // store.commit('app/setAlert', {shown: true, text: 'Ошибка ответа от сервера', color: 'error'})
      store.commit("app/setModal", {
        shown: true,
        title: "Ошибка",
        text: "При отправке обращения возникала ошибка.",
        color: "error",
      });
      console.log(e);
    }
  },
};
